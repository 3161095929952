import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Stack, Typography } from "@mui/material";
import { useCallback, useState } from "react";
import { createApiResponse } from "../../shared/api/axiosHelper";
import { MetricCellValueUpdate } from "../../shared/api/dataCollectionTypes";
import {
  DataSubmissionLayout,
  LayoutBlock,
  MetricTableBlockRow,
  ResolvedBlockContent,
} from "../../shared/api/inputFormTypes";
import DataSubmissionForm from "../../shared/components/dataCollection/submissionForms/DataSubmissionForm";
import {
  addCellValueUpdatesAction,
  EditedFormValuesState,
  getFormUpdatesPerBlock,
  getInitialEditedFormValuesState,
} from "../../shared/components/dataCollection/submissionForms/editedFormValuesState";
import useToggleState from "../../shared/hooks/useToggleState";

const recipientObjectId = "recipient-id";
const blockId = "block-id";

const block: LayoutBlock = {
  id: blockId,
  type: "InternalTable",
  attributes: {},
};

const layout: DataSubmissionLayout = {
  blocks: [block],
};

const generateRow = (index: number): MetricTableBlockRow => {
  const isMoney = index % 3 !== 0;
  return {
    type: "Metric",
    id: `row-${index}`,
    metricId: `metric-id-${index}`,
    dataType: isMoney ? "Money" : "Text",
    name: `Metric ${index}`,
    values: {
      "col-1": isMoney ? 1000 + index * 100 : "",
      "col-2": isMoney ? 900 + index * 87 : `Value ${index}`,
      "col-3": isMoney ? 800 + index * 75 : `Value ${index}`,
      "col-4": isMoney ? undefined : "",
      "col-5": isMoney ? 700 + index * 57 : `Value ${index}`,
      "col-6": isMoney ? 600 + index * 43 : `Value ${index}`,
    },
  };
};

const generateRows = (count: number): MetricTableBlockRow[] => {
  const rows: MetricTableBlockRow[] = [];

  for (let i = 1; i <= count; ++i) {
    rows.push(generateRow(i));
  }

  return rows;
};

const createBlockContents = (count: number): ResolvedBlockContent[] => [
  {
    blockId: blockId,
    content: {
      currencyCode: "EUR",
      table: {
        name: "Monthly",
        rows: generateRows(count),
        columns: [
          {
            id: "col-1",
            type: "Scenario",
            isEditable: true,
            scenarioId: "s-1",
            scenarioName: "Actual",
            subtitle: "Current",
            titleAsDate: "2025-03-31",
            dateRange: "Current",
            period: "Month",
          },
          {
            id: "col-2",
            type: "Scenario",
            isEditable: true,
            scenarioId: "s-2",
            scenarioName: "Budget",
            subtitle: "Previous",
            titleAsDate: "2025-02-28",
            dateRange: "PeriodShift",
            period: "Month",
          },
          {
            id: "col-3",
            type: "Scenario",
            isEditable: true,
            scenarioId: "s-2",
            scenarioName: "Budget",
            subtitle: "Previous",
            titleAsDate: "2025-01-31",
            dateRange: "PeriodShift",
            period: "Month",
          },
          {
            id: "col-4",
            type: "Scenario",
            isEditable: true,
            scenarioId: "s-2",
            scenarioName: "Budget",
            subtitle: "Previous",
            titleAsDate: "2024-12-31",
            dateRange: "PeriodShift",
            period: "Month",
          },
          {
            id: "col-5",
            type: "Scenario",
            isEditable: false,
            scenarioId: "s-2",
            scenarioName: "Budget",
            subtitle: "Budget",
            titleAsDate: "2024-01-01",
            dateRange: "Ytd",
            period: "Month",
          },
          {
            id: "col-6",
            type: "Scenario",
            isEditable: false,
            scenarioId: "s-2",
            scenarioName: "Budget",
            subtitle: "Budget",
            titleAsDate: "2024-03-31",
            dateRange: "LastTwelveMonths",
            period: "Month",
          },
        ],
      },
    },
    capabilities: [],
  },
];

const minRowCount = 20;
const maxRowCount = 980;
const increment = 10;

const InputFormDemoPage = () => {
  const [rowCount, setRowCount] = useState(minRowCount);
  const [blockContents, setBlockContents] = useState(createBlockContents(minRowCount));
  const [renderKey, toggleRenderKey] = useToggleState(true);
  const [editedFormValues, setEditedFormValues] = useState<EditedFormValuesState>(getInitialEditedFormValuesState());

  const handleBlockCellValuesEdit = useCallback((blockId: string, cellValueUpdates: MetricCellValueUpdate[]) => {
    setEditedFormValues(addCellValueUpdatesAction(blockId, cellValueUpdates));
  }, []);

  const handleDecrement = useCallback(() => {
    const newValue = Math.max(minRowCount, rowCount - increment);
    setRowCount(newValue);
    setBlockContents(createBlockContents(newValue));
    toggleRenderKey();
  }, [rowCount, toggleRenderKey]);

  const handleIncrement = useCallback(() => {
    const newValue = Math.min(maxRowCount, rowCount + increment);
    setRowCount(newValue);
    setBlockContents(createBlockContents(newValue));
    toggleRenderKey();
  }, [rowCount, toggleRenderKey]);

  return (
    <Stack spacing={2} flex={1} py={2} px={3}>
      <Stack direction="row" spacing={2} px={3} alignItems="center">
        <Button variant="outlined" onClick={handleDecrement}>
          {`-${increment.toLocaleString()}`}
        </Button>
        <Typography variant="subtitle2">{rowCount}</Typography>
        <Button variant="outlined" onClick={handleIncrement}>
          {`+${increment.toLocaleString()}`}
        </Button>
      </Stack>

      <DataSubmissionForm
        key={renderKey.toString()}
        recipientObjectId={recipientObjectId}
        layout={layout}
        blockContents={blockContents}
        isSubmissionEditable
        metricExtensionsService={{
          searchObjectMetricExtensions: () => Promise.resolve(createApiResponse([])),
          createObjectMetricExtensions: () =>
            Promise.resolve(
              createApiResponse([{ id: "id", metricId: "id", value: "ext", objectId: recipientObjectId }])
            ),
        }}
        onBlockCellValuesEdit={handleBlockCellValuesEdit}
      />

      <Accordion sx={(t) => ({ bgcolor: t.palette.background.grey })}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="subtitle1" component="span">
            State
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box
            component="pre"
            p={2}
            sx={(t) => ({ bgcolor: t.palette.background.grey, border: `1px solid ${t.palette.divider}` })}
            width="50%"
          >
            {JSON.stringify(getFormUpdatesPerBlock(editedFormValues), null, 2)}
          </Box>
        </AccordionDetails>
      </Accordion>
    </Stack>
  );
};

export default InputFormDemoPage;
